import { isIOS, isIPad } from "./tools";
import { config } from "./config";
import { downLoad, downloadPoint } from "./sensorsdata";
import { http } from "./http";
import CallApp from "callapp-lib";

import { isMobile } from "pages/forMobileInvite/hooks/isMobile";

import { isWeiXin } from "pages/PureHooks/isMobile";
import {downloadUrl} from "@/constants/url";

// let timer: any;
// var downloadBtn = document.getElementById('download');
// downloadBtn.addEventListener('click', downloadBtnClick)

function firstIosOpen() {
  if (isIOS || isIPad) {
    gotoAppStore();
  }
}

// 下载按钮点击方法
function downloadBtnClick(pullUpMethod: any, force_download = false, force_mobile = false, platform = 'phone') {
  if (isWeiXin()) {
    alert("请点击右上角，选择浏览器打开");
    return;
  }

  if (!isMobile() && !force_mobile) {
    location.href = downloadUrl;
    return;
  }

  evokeApp(pullUpMethod, force_download, function () {
    downLoad();
    if (isIOS || isIPad) {
      gotoAppStore();
    } else {
      const platformValue =  platform === 'phone' ? 1 : 6; // 6: AndroidHD（安卓平板），1: Android（普通安卓手机）

      http
        .get("/v1/downloads/upgrade_version", {
          params: {
            platform: platformValue, // 1:Android  6: AndroidHD（安卓平板），
            version_number: "0.0.1",
          },
        })
        .then((res) => {
          var linkUrl = res.data.downloads.full_version.link_url;
          var versionNumber = res.data.version_number;
          if (!linkUrl) {
            alert("下载地址获取失败");
          } else {
            console.log("========", linkUrl);
            downloadApk(linkUrl, `flyeleV${versionNumber}`);
            // downloadPoint("goDownload");
          }
        })
        .catch((e) => {
          alert("获取app失败");
        });
    }
  });
}

// 唤起app
function evokeApp(pullUpMethod: any, force_download = false, callback: any) {
  // 检测设备是否为iPad
  const isIPad = navigator.userAgent.match(/iPad/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  // 根据设备类型选择正确的App ID
  const appID = isIPad ? config.iOSIPadId : config.iOSAppId;

  const callLib = new CallApp({
    scheme: {
      protocol: "flyele",
    },
    appstore: `https://itunes.apple.com/app/id${appID}`,
    yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=com.flyele.flyeleMobile',
    fallback: config.HOMEPAGE,
    logFunc: (status) => {
      console.log("status", status);
      if (status === "failure") {
        callback();
      } else if (status === "pending") {
        // downloadPoint("openApp");
      }
    },
  });

  if (force_download) {
    return callback();
  }

  // callback();
  // function aWakeUp() {
  //   const newALink = document.createElement("a");
  //   newALink.href = "flyele://";
  //   newALink.click();
  // }

  if (pullUpMethod === "不打开APP直接走下载") {
    callback();
  } else if (pullUpMethod === "APP") {
    callLib.open({
      path: "",
      callback: () => {},
    });
  } else if (pullUpMethod === "日程") {
    callLib.open({
      path: "schedule",
      callback: () => {},
    });
  } else if (pullUpMethod === "动态") {
    callLib.open({
      path: "dynamic",
      callback: () => {},
    });
  } else {
    callLib.open({
      path: "",
      callback: () => {},
    });
  }
}

// 跳转到appstore
function gotoAppStore() {
  // 检测设备是否为iPad
  const isIPad = navigator.userAgent.match(/iPad/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  // 根据设备类型选择正确的App ID
  const appID = isIPad ? config.iOSIPadId : config.iOSAppId;
  const appstore = `itms-apps://itunes.apple.com/app/id${appID}`;
  executeUrl(appstore);
  // 神策
  downloadPoint("appStore");
}

// 下载 安卓apk
function downloadApk(href: any, name: any) {
  executeUrl(href, name);
  // 神策
  downloadPoint("goDownload");
}

// 执行某个url
function executeUrl(url: any, downloadUrl = "") {
  var a = document.createElement("a");
  a.setAttribute("href", url);
  if (downloadUrl) {
    a.setAttribute("download", downloadUrl);
  }
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export { downloadBtnClick, firstIosOpen, evokeApp };
