import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {isIOS, isIPad} from "./ts/tools";
import "./index.scss";

import {downloadBtnClick, evokeApp, firstIosOpen} from "./ts/download";
import {loaded, pageLeave, sensorsLocation} from "./ts/sensorsdata";
import {isMobile} from "pages/forMobileInvite/hooks/isMobile";
import {useMemoizedFn} from "ahooks";
import {downloadUrl} from "@/constants/url";

const Html5Page = (props: any) => {
  const { history } = props;

  const [pullUpMethod, set_pullUpMethod] = useState("APP");
  const [showWeiXin, set_showWeiXin] = useState(false);
  const [
    ,
    // force_download
    set_force_download,
  ] = useState(false);

  useEffect(() => {
    console.log("【当前参数】", history?.location.search);

    const { search = "" } = history.location;

    function isMobile() {
      const sUserAgent = navigator.userAgent;
      if (
        sUserAgent.indexOf("Android") > -1 ||
        sUserAgent.indexOf("iPhone") > -1 ||
        sUserAgent.indexOf("iPad") > -1 ||
        sUserAgent.indexOf("iPod") > -1 ||
        sUserAgent.indexOf("Symbian") > -1 ||
        isWeiXin()
      ) {
        return true;
      }

      return false;
    }

    if (!isMobile()) return;

    if (isWeiXin()) {
      set_showWeiXin(true);
      firstIosOpen();
      if (isIOS || isIPad) {
        set_force_download(true);
        set_pullUpMethod("不打开APP直接走下载");
      }
    }

    // 假如时强制下载，就不拉起
    if (search.includes("force_download=true")) {
      return set_force_download(true);
    }

    let typeName = "APP";

    if (search.includes("open_app=schedule")) {
      set_pullUpMethod("日程");
      typeName = "日程";
    } else if (search.includes("open_app=normal")) {
      set_pullUpMethod("APP");
      typeName = "APP";
    } else if (search.includes("open_app=dynamic")) {
      set_pullUpMethod("动态");
      typeName = "动态";
    }

    if (!isWeiXin() && isMobile() && !search.includes("noDownload=true")) {
      evokeApp(typeName, false, () => {});
    }
  }, [history.location]);

  // 加载完后埋点
  useLayoutEffect(() => {
    /** 加载后埋点 **/
    loaded();

    let time = 0;
    setInterval(() => {
      time += 1000;
    }, 1000);

    /** 定位埋点 **/
    sensorsLocation();

    /** 离开埋点 **/
    window.onunload = function () {
      pageLeave(time);
    };
  }, []);

  function isWeiXin() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (
      (ua.match(/MicroMessenger/i) as unknown as string) == "micromessenger"
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 是否安卓移动端
   * 包括手机和平板
   */
  const isAndMobileAndNotWeiXin = useMemo(() => {
    const { search = "" } = history.location;
    const force_mobile = search.includes("force_mobile=true")
    const noMobile = !isMobile() && !force_mobile

    if (noMobile) {
      return false
    }

    const and = !isWeiXin() && !isIOS && !isIPad

    return and
  }, [history.location])

  const downloadClick = useMemoizedFn((pullUpMethod: any, force_download = false, platform = 'phone') => {
    const { search = "" } = history.location;
    const force_mobile = search.includes("force_mobile=true")

    /**
     * 有用户使用vivo手机的默认浏览器，调整浏览器模式为电脑端
     * 导致手机浏览器打开也不能成功识别为手机浏览器，所以app端增加 force_mobile 参数
     * 控制强制走下面的逻辑
     */
    if (!isMobile() && !force_mobile) {
      location.href = downloadUrl;
      return;
    }

    if (isWeiXin() && !isIOS && !isIPad) {
      set_showWeiXin(true);
      return;
    }
    downloadBtnClick(pullUpMethod, force_download, force_mobile, platform);
  })

  /** 暂时不知道有啥用 **/
  // function buildBtn() {
  //   return (
  //     <button
  //       className="downloadButton"
  //       id="download"
  //       onClick={() => downloadClick(pullUpMethod, false)}
  //     >
  //       打开APP
  //     </button>
  //   );
  // }

  return (
    <div id="h5" style={{ overflow: showWeiXin ? "hidden" : "auto" }}>
      <div
        onClick={() => set_showWeiXin(false)}
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          paddingTop: 10,
          background: "rgba(0,0,0,0.5)",
          height: "100vh",
          width: "100%",
          zIndex: 9999999,
          display: showWeiXin ? "block" : "none",
          textAlign: "center",
        }}
      >
        <img src="/assets/header.png" alt="" style={{ maxWidth: "70vw" }} />
      </div>
      <div
        className="container"
        style={{
          background: "url(/image/bg.jpg) no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "top",
        }}
      >
        <div className="appInfo">
          <div className="logoContainer">
            <img src="/image/splash_logo.png" alt="logo" className="logo" />
          </div>
          <p className="appName">飞项</p>
          <p className="declaration xj1">“把每件事儿都干得漂漂亮亮”</p>
        </div>
        <div
          className="schedule"
          style={{
            background: "url(/image/schedule2.png) no-repeat center top",
          }}
        />
        <div className="version-container">
          <div className="version">
            <div className="v-header">
              <a href="https://cdn.flyele.net/agreements/software-license-agreement.html">
                《权限列表》
              </a>
              <span>|</span>
              <a href="https://cdn.flyele.net/agreements/privacy-policy.html">
                《隐私政策》
              </a>
            </div>
            <p className="company">开发者名称: 飞项科技（广州）有限公司</p>
            <p className="v-code">版本号：2.2.0</p>
          </div>
        </div>
      </div>
      <div className="downloadContainer">
        <div className="filter" />
        <div className="btnBox">
          {isAndMobileAndNotWeiXin ? (
            <>
              <button
                className="downloadButton"
                id="download"
                onClick={() => downloadClick(pullUpMethod, true, 'phone')}
              >
                手机端下载
              </button>
              <button
                className="downloadButton"
                id="download"
                onClick={() => downloadClick(pullUpMethod, true, 'pad')}
              >
                平板端下载
              </button>
            </>
          ) : (
            <button
              className="downloadButton"
              id="download"
              onClick={() => downloadClick(pullUpMethod, true)}
            >
            立即下载
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Html5Page;
